import HttpClient from 'src/common/HttpClient'
import { setLoading } from './UserActions'
import { NotificationManager } from 'react-notifications'

export const TYPES = {
  SET_ADD_ROLE: 'SET_ADD_ROLE',
  SET_UPDATE_ROLE: 'SET_UPDATE_ROLE',
  SET_REMOVE_ROLE: 'SET_REMOVE_ROLE',
  SET_GET_ALL_ROLE: 'SET_GET_ALL_ROLE',
  SET_GET_BY_ID_ROLE: 'SET_GET_BY_ID_ROLE',
}

//--------------------------------------------------------//
//------------------ Action Dispatch ---------------------//
//--------------------------------------------------------//

export const setAddRole = (addRole) => ({
  type: TYPES.SET_ADD_ROLE,
  payload: { addRole },
})

export const setUpdateRole = (updateRole) => ({
  type: TYPES.SET_UPDATE_ROLE,
  payload: { updateRole },
})

export const setRemoveRole = (deleteRole) => ({
  type: TYPES.SET_REMOVE_ROLE,
  payload: { deleteRole },
})

export const setGetAllRoles = (allRoles) => ({
  type: TYPES.SET_GET_ALL_ROLE,
  payload: { allRoles },
})

export const setGetByIdRoles = (byIdRole) => ({
  type: TYPES.SET_GET_BY_ID_ROLE,
  payload: { byIdRole },
})

//--------------------------------------------------------//
//----------------- Action Controller --------------------//
//--------------------------------------------------------//

export const postAddRole = (payload) => async (dispatch) => {
  HttpClient.setAuthorization(localStorage.getItem('token'))
  dispatch(setLoading(true))
  const params = {
    url: `/roles/create`,
    data: payload,
  }

  const response = await HttpClient.post(params)
  if (response) {
    dispatch(setLoading(false))
  }
}

export const putUpdateRole = (payload) => async (dispatch) => {
  const params = {
    url: `/roles/update`,
    data: payload,
  }

  const response = await HttpClient.post(params)
  if (response && response.data.success) {
  }
}
export const deleteRole = (payload, navigate) => async (dispatch) => {
  dispatch(setLoading(true))
  HttpClient.setAuthorization(localStorage.getItem('token'))
  const params = {
    url: 'roles/delete',
    data: payload
  }

  const response = await HttpClient.post(params)
  return response
}
export const deleteRemoveRole = (id) => async (dispatch) => {
  HttpClient.setAuthorization(localStorage.getItem('token'))
  const params = {
    url: `roles/delete`,
    data: {id},
  }

  return await HttpClient.post(params)
}

export const getAllRemoveRoles = () => async (dispatch) => {
  HttpClient.setAuthorization(localStorage.getItem('token'))
  const params = {
    url: `roles`,
    data: {},
  }
  const response = await HttpClient.get(params)
  
  if (response) {
    dispatch(setGetAllRoles(response))
    return response
  }
}

export const getByIdRole = (id) => async (dispatch) => {
  HttpClient.setAuthorization(localStorage.getItem('token'))
  const params = {
    url: `roles/get/${id}`,
    data: {},
  }

  const response = await HttpClient.get(params)
  
  if (response) {
    dispatch(setUpdateRole(response))
  }
}
export const getById = (payload, navigate) => async (dispatch) => {
  dispatch(setLoading(true))
  HttpClient.setAuthorization(localStorage.getItem('token'))
  const params = {
    url: `roles/showById/${payload.id}`,
  }

  const response = await HttpClient.get(params)
  return response
}
