import { TYPES } from '../actions/SidebarActions'

export const sidebarReducer = (
  state = {
    sidebarUnfoldable: false,
    sidebarShow: true,
  },
  { payload, type },
) => {
  switch (type) {
    case TYPES.SET_SIDEBAR_UNFOLDABLE:
      return { ...state, sidebarUnfoldable: payload.sidebarUnfoldable }
    case TYPES.SET_SIDEBAR_SHOW:
      return { ...state, sidebarShow: payload.sidebarShow }
    default:
      return state
  }
}
