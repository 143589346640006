import { TYPES } from '../actions/RoleActions'

export const RoleReducer = (
  state = {
    addRole: {},
    updateRole: {},
    deleteRole: {},
    allRoles: [],
    byIdRole: {},
  },
  { payload, type },
) => {
  switch (type) {
    case TYPES.SET_ADD_ROLE:
      return { ...state, addRole: payload.addRole }
    case TYPES.SET_UPDATE_ROLE:
      return { ...state, updateRole: payload.updateRole }
    case TYPES.SET_REMOVE_ROLE:
      return { ...state, deleteRole: payload.deleteRole }
    case TYPES.SET_GET_ALL_ROLE:
      return { ...state, allRoles: payload.allRoles }
    case TYPES.SET_GET_BY_ID_ROLE:
      return { ...state, byIdRole: payload.byIdRole }
    default:
      return state
  }
}
