import HttpClient from 'src/common/HttpClient'
import {CUSTOMER_ROLE_NAME} from 'src/constants/app'

export const TYPES = {
  SET_LOADING: 'SET_LOADING',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
}

//--------------------------------------------------------//
//------------------ Action Dispatch ---------------------//
//--------------------------------------------------------//

export const setLoading = (loading) => ({
  type: TYPES.SET_LOADING,
  payload: { loading },
})

export const setCurrentUser = (currentUser) => ({
  type: TYPES.SET_CURRENT_USER,
  payload: { currentUser },
})

//--------------------------------------------------------//
//----------------- Action Controller --------------------//
//--------------------------------------------------------//

export const login = (payload, navigate) => async (dispatch) => {
  dispatch(setLoading(true))
  const params = {
    url: 'login',
    data: payload,
  }
  let home  = "/dashboard";
  const response = await HttpClient.post(params)
  if (response && response.data.success) {
    const token = response.data.token
    const user = response.data.data
    const role = response.data.role
    const compteClient = response.data.compteClient
    localStorage.setItem('token', token)
    localStorage.setItem('user', JSON.stringify(user))
    HttpClient.setAuthorization(token);

    if(compteClient?.client)localStorage.setItem('customerHC', JSON.stringify(compteClient.client))
    
    if(role){
      console.log("role" , role)
      localStorage.setItem('role', JSON.stringify(role))
      if(role?.roleName === CUSTOMER_ROLE_NAME) home = "/home-client"
    }
    navigate(home)
    dispatch(setLoading(false))

  } else {
    dispatch(setLoading(false))
    return response;
  }
}

export const register = (payload, navigate) => async (dispatch) => {
  dispatch(setLoading(true))
  const params = {
    url: 'register',
    data: payload,
  }

  const response = await HttpClient.post(params)
  if (response && response.data.success) {
    navigate('/')
    dispatch(setLoading(false))
  } else {
    dispatch(setLoading(false))
  }
}

export const equipes = (payload, navigate) => async (dispatch) => {
  dispatch(setLoading(true))
  HttpClient.setAuthorization(localStorage.getItem('token'))
  const params = {
    url: 'equipes'
  }
  const response = await HttpClient.get(params)
  return response
}


