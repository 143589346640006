import { TYPES } from '../actions/UserActions'

export const userReducer = (
  state = {
    loading: false,
    currentUser: {},
  },
  { payload, type },
) => {
  switch (type) {
    case TYPES.SET_LOADING:
      return { ...state, loading: payload.loading }
    case TYPES.SET_CURRENT_USER:
      return { ...state, currentUser: payload.currentUser }
    default:
      return state
  }
}
