import { combineReducers } from 'redux'
import { userReducer } from './UserReducer'
import { sidebarReducer } from './SidebarReducer'
import { RoleReducer } from './RoleReducer';
import { TeamReducer } from './TeamReducer';

const reducers = combineReducers({ sidebarReducer, userReducer, RoleReducer, TeamReducer })

export const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    state = undefined
  }
  return reducers(state, action)
}
