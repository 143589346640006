export const TYPES = {
  SET_SIDEBAR_UNFOLDABLE: 'SET_SIDEBAR_UNFOLDABLE',
  SET_SIDEBAR_SHOW: 'SET_SIDEBAR_SHOW',
}

//--------------------------------------------------------//
//------------------ Action Dispatch ---------------------//
//--------------------------------------------------------//

export const setSidebarUnfoldable = (sidebarUnfoldable) => ({
  type: TYPES.SET_SIDEBAR_UNFOLDABLE,
  payload: { sidebarUnfoldable },
})

export const setSidebarShow = (sidebarShow) => ({
  type: TYPES.SET_SIDEBAR_SHOW,
  payload: { sidebarShow },
})
