import HttpClient from 'src/common/HttpClient'
import { setLoading } from './UserActions'
import { NotificationManager } from 'react-notifications'

export const TYPES = {
  SET_ADD_TEAM: 'SET_ADD_TEAM',
  SET_UPDATE_TEAM: 'SET_UPDATE_TEAM',
  SET_REMOVE_TEAM: 'SET_REMOVE_TEAM',
  SET_GET_ALL_TEAM: 'SET_GET_ALL_TEAM',
  SET_GET_BY_ID_TEAM: 'SET_GET_BY_ID_TEAM',
}

//--------------------------------------------------------//
//------------------ Action Dispatch ---------------------//
//--------------------------------------------------------//

export const setAddTeam = (addTeam) => ({
  type: TYPES.SET_ADD_TEAM,
  payload: { addTeam },
})

export const setUpdateTeam = (updateTeam) => ({
  type: TYPES.SET_UPDATE_TEAM,
  payload: { updateTeam },
})

export const setRemoveTeam = (deleteTeam) => ({
  type: TYPES.SET_REMOVE_TEAM,
  payload: { deleteTeam },
})

export const setGetAllTeams = (allTeams) => ({
  type: TYPES.SET_GET_ALL_TEAM,
  payload: { allTeams },
})

export const setGetByIdTeam = (getIdTeam) => ({
  type: TYPES.SET_GET_BY_ID_TEAM,
  payload: { getIdTeam },
})

//--------------------------------------------------------//
//----------------- Action Controller --------------------//
//--------------------------------------------------------//

export const postAddTeam = (payload) => async (dispatch) => {
  HttpClient.setAuthorization(localStorage.getItem('token'))
  dispatch(setLoading(true))
  const params = {
    url: `/equipes/create`,
    data: payload,
  }

  const response = await HttpClient.post(params)
  if (response) {
    dispatch(setLoading(false))
 
  }
}

export const putUpdateTeam = (payload) => async (dispatch) => {
  const params = {
    url: `/equipes/update`,
    data: payload,
  }

  const response = await HttpClient.post(params)
  if (response && response.data.success) {
  }
}

export const deleteRemoveTeam = (id) => async (dispatch) => {
  HttpClient.setAuthorization(localStorage.getItem('token'))
  const params = {
    url: `equipes/delete`,
    data: {id},
  }

  return await HttpClient.post(params)
}

export const getAllTeams = () => async (dispatch) => {
  HttpClient.setAuthorization(localStorage.getItem('token'))
  const params = {
    url: `equipes`,
    data: {},
  }
  const response = await HttpClient.get(params)
  if (response) {
    return response;
  }
}

export const getByIdTeam = (id) => async (dispatch) => {
  HttpClient.setAuthorization(localStorage.getItem('token'))
  const params = {
    url: `equipes/show/${id}`,
    data: {},
  }

  const response = await HttpClient.get(params)
  if (response) {
    return response
  }
}
export const deleteUser = (payload, navigate) => async (dispatch) => {
  dispatch(setLoading(true))
  HttpClient.setAuthorization(localStorage.getItem('token'))
  const params = {
    url: 'equipes/delete',
    data: payload
  }

  const response = await HttpClient.post(params)
  return response
}