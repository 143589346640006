import { TYPES } from '../actions/TeamActions'

export const TeamReducer = (
  state = {
    addTeam: {},
    updateTeam: {},
    deleteTeam: {},
    allTeams: [],
    getIdTeam: {},
  },
  { payload, type },
) => {
  switch (type) {
    case TYPES.SET_ADD_TEAM:
      return { ...state, addTeam: payload.addTeam }
    case TYPES.SET_UPDATE_TEAM:
      return { ...state, updateTeam: payload.updateTeam }
    case TYPES.SET_REMOVE_TEAM:
      return { ...state, deleteTeam: payload.deleteTeam }
    case TYPES.SET_GET_ALL_TEAM:
      return { ...state, allTeams: payload.allTeams }
    case TYPES.SET_GET_BY_ID_TEAM:
      return { ...state, getIdTeam: payload.getIdTeam }
    default:
      return state
  }
}
